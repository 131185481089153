import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-calio-alert',
  templateUrl: './calio-alert.component.html',
  standalone: true,
  imports: [TranslateModule, NgClass]
})
export class CalioAlertComponent {

  @Input() type: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() customClass: { [key:string]: boolean } = {};
}
